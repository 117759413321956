import Work from '../../models/work'
import Callout from '../callout'
import Page, { Header } from '../page'

interface PageWorkProps {
  work: Work
}

const PageWork = ({ work }: PageWorkProps) => (
  <>
    <Page container>
      <Header title={work.name} subtitle={work.description} />
    </Page>
    <Callout />
  </>
)

export default PageWork
