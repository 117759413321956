import { graphql } from 'gatsby'
import PageWork from '../../components/page-work'
import Seo from '../../components/seo'
import Work from '../../models/work'

interface WorkProps {
  data: {
    work: Work
  }
}

const WorkComponent = ({ data: { work } }: WorkProps) => (
  <>
    <Seo title={work.name} />
    <PageWork work={work} />
  </>
)

export default WorkComponent

export const query = graphql`
  query ($id: String!) {
    work(id: { eq: $id }) {
      id
      name
      description
    }
  }
`
